const consts = {
    // MainUrl: "http://localhost:3000",
    // Main: "Development",
    // BackendUrl: "http://localhost:3500/api",
    // socketurl: 'http://localhost:3500',

    route: "", 


    MainUrl: "https://app.dtracer.io/",
    BackendUrl: "https://app.dtracer.io/api",
    Main: "Production",
    socketurl: "https://app.dtracer.io",

    // MainUrl: "https://testing.dtracer.io/",
    // BackendUrl: "https://testing.dtracer.io/api",
    // Main: "Production",
    // socketurl: "https://testing.dtracer.io",

    polygon_url: "https://polygon-bor-rpc.publicnode.com",  //mainnet
    contractAddress: "0x273AACbc4412494e7164cC49DaA6d1156bfF2b90", // polygon mainnet
    URL: "https://polygonscan.com/",  //mainnet
    polygon_api_key: 'R8CUS27RXIMYCU5VKIPWX3AHANP8HZQEHB', // mainnet
    // URL: "https://amoy.polygonscan.com/", //testnet
    // polygon_url: "https://polygon-amoy-bor-rpc.publicnode.com", // testnet
    // contractAddress: "0x0708D75506D37c6B25f32C9DeD233A103B8005E3", // amoy testnet

    languageOption: "false"

    // URL: "https://amoy.polygonscan.com/", //testnet
    // polygon_url: "https://polygon-amoy-bor-rpc.publicnode.com", // testnet
    // contractAddress: "0x0708D75506D37c6B25f32C9DeD233A103B8005E3", // amoy testnet
};

export default consts;